// project import
import dashboard from "./navigation";
import utilities from "./utilities";

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
  items: [dashboard, utilities],
};

export default MenuItems;
