import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// scroll bar
import "simplebar/src/simplebar.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// apex-chart
import "Assets/third-party/apex-chart.css";
import { DeleteContextProvider } from "Contexts/DeleteModal-Context";
import { AddCustomerContextProvider } from "Contexts/AddCustomerModal-Context";
// project import
import App from "./App";
import { store } from "Store";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ReduxProvider store={store}>
    <Router>
      <AddCustomerContextProvider>
        <DeleteContextProvider>
          <App />
        </DeleteContextProvider>
      </AddCustomerContextProvider>
    </Router>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
