import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArchiveIcon from "@mui/icons-material/Archive";
import PrintIcon from "@mui/icons-material/Print";
import productOne from "Assets/images/product001.webp";
import productTwo from "Assets/images/product03.webp";
import productThree from "Assets/images/product06.webp";

import {
  Header,
  SubHeader,
  Container,
  MainBox,
  SpanCode,
  DateSpan,
  AddCustomerDropDown,
  AddCustomerDropDownWrapper,
  HeaderBox,
  SubHeaderBox,
  IconSpan,
  ProductItem,
  ProductImage,
  ProductName,
  ProductQuantity,
  ProductPrice,
  ProductSubtotal,
  Spacer,
  ProductNotesLabel,
  ProductNotesInput,
  ProductDetails,
  NotesColumn,
} from "./NewOrder.styled";

export const NewOrder = () => {
  const [status, setStatus] = useState("Status");
  const [price, setPrice] = useState(0);
  const [isFulfilling, setIsFulfilling] = useState(false);

  const [productNotes, setProductNotes] = useState({
    "Product A": "",
    "Product B": "",
  });

  const handleFulfillingToggle = () => {
    setIsFulfilling((prevState) => !prevState);
  };
  const handleStatusChange = (event) => {
    setStatus(event);
  };

  const orderItems = [
    {
      name: "Product A",
      imageSrc: productOne,
      quantity: 2,
      price: 10,
      notes: "This is a note for Product A.", // Add a notes field for Product A
    },
    {
      name: "Product B",
      imageSrc: productTwo,
      quantity: 3,
      price: 15,
      notes: "This is a note for Product B.", // Add a notes field for Product B
    },
  ];

  const handleNotesChange = (event, productName) => {
    const { value } = event.target;
    setProductNotes((prevNotes) => ({
      ...prevNotes,
      [productName]: value,
    }));
  };

  const subtotal = orderItems.reduce(
    (total, item) => total + item.quantity * item.price,
    0
  );
  const shipping = 5;
  const tax = 19;
  const internationalShipping = 10; // Add international shipping amount (modify as needed)

  return (
    <Container>
      <Header>
        <SpanCode>#1063</SpanCode>
        <DateSpan>September 18, 2017 at 8:47</DateSpan>
      </Header>
      <SubHeader>
        <Stack direction="row" spacing={2} alignItems="center" mr={2} mb={2}>
          <Button color="success" startIcon={<PrintIcon />}>
            Print order
          </Button>
        </Stack>
        <AddCustomerDropDownWrapper>
          <AddCustomerDropDown
            title={status}
            eventKey="status"
            onSelect={handleStatusChange}
          >
            <Dropdown.Item eventKey="Duplicate">
              {" "}
              <IconSpan>
                <ContentCopyIcon />
              </IconSpan>
              Duplicate
            </Dropdown.Item>
            <Dropdown.Item eventKey="Cancel order">
              <IconSpan>
                <CloseIcon />
              </IconSpan>
              Cancel order
            </Dropdown.Item>
            <Dropdown.Item eventKey="Archive">
              <IconSpan>
                <ArchiveIcon />
              </IconSpan>
              Archive
            </Dropdown.Item>
            <Dropdown.Item eventKey="View order status page">
              <IconSpan>
                <VisibilityIcon />
              </IconSpan>
              View order status page
            </Dropdown.Item>
          </AddCustomerDropDown>
        </AddCustomerDropDownWrapper>
      </SubHeader>

      <MainBox>
        <HeaderBox>
          <span>Order detail</span>
          <span>From Online Store</span>
        </HeaderBox>
        <SubHeaderBox>Unfulfilled</SubHeaderBox>
        <hr />

        {/* Loop through each product and display its details */}
        {orderItems.map((item, index) => (
          <ProductItem key={index}>
            {isFulfilling && (
              <Form.Group className="mb-2 mt-2 d-flex">
                <Form.Check type="checkbox" id={`checkbox-${index}`} />
              </Form.Group>
            )}
            <ProductImage src={item.imageSrc} alt={item.name} />
            <NotesColumn>
              <ProductName>{item.name}</ProductName>

              <ProductNotesLabel>Notes</ProductNotesLabel>
              <ProductNotesInput
                type="text"
                placeholder="Enter notes here"
                value={productNotes[item.name]}
                onChange={(e) => handleNotesChange(e, item.name)}
              />
            </NotesColumn>
            <ProductDetails>
              <ProductQuantity>{item.quantity}</ProductQuantity>
              <Spacer>x</Spacer>
              <ProductPrice>${item.price}</ProductPrice>
              <ProductSubtotal>${item.quantity * item.price}</ProductSubtotal>
            </ProductDetails>
          </ProductItem>
        ))}

        <ProductItem>
          <span>Shipping:</span>
          <span>${shipping}</span>
        </ProductItem>
        {tax > 0 && (
          <ProductItem>
            <span>Tax:</span>
            <span>${tax}</span>
          </ProductItem>
        )}

        {/* Display international shipping only if it's greater than 0 */}
        {internationalShipping > 0 && (
          <ProductItem>
            <span>International Shipping:</span>
            <span>${internationalShipping}</span>
          </ProductItem>
        )}
        <ProductItem>
          <span>Total:</span>
          <span>${subtotal + shipping + tax}</span>
        </ProductItem>

        {/* Payment details (assuming paid by customer) */}
        <ProductItem>
          <span>Paid by Customer</span>
          <span>$0.0</span>
        </ProductItem>
      </MainBox>

      {/* Payment Pending Row */}
      <MainBox>
        <HeaderBox>
          <span>Payment Pending</span>
          <Button
            variant="contained"
            color="success"
            sx={{ background: "#00a84f" }}
          >
            Mark as Paid
          </Button>
        </HeaderBox>
      </MainBox>

      {/* Fulfilling Items Row */}
      <MainBox>
        <HeaderBox>
          <span>1 item to fullfill</span>
          <Button
            variant="outlined"
            onClick={handleFulfillingToggle}
            sx={{
              color: "#00a84f",
              borderColor: "#00a84f",
              "&:hover": { borderColor: "#00a84f" },
            }}
          >
            {!isFulfilling ? "Start fulfilling" : "Received"}
          </Button>
        </HeaderBox>
      </MainBox>
    </Container>
  );
};

export default NewOrder;
