// Assets
import {
  SettingOutlined,
  QuestionCircleOutlined,
  PrinterOutlined,
  UserOutlined,
  SafetyOutlined,
  BellOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  SettingOutlined,
  QuestionCircleOutlined,
  PrinterOutlined,
  UserOutlined,
  SafetyOutlined,
  BellOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "settings",
      title: "Settings",
      type: "item",
      url: "/settings",
      icon: icons.SettingOutlined,
    },
    {
      id: "support",
      title: "Support",
      type: "item",
      url: "/support",
      icon: icons.QuestionCircleOutlined,
    },
    {
      id: "privacy",
      title: "Privacy Center",
      type: "item",
      url: "/privacy",
      icon: icons.PrinterOutlined,
    },
  ],
};

export default utilities;
