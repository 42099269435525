import Stack from "@mui/material/Stack";
import { Container } from "@mui/material";
import { ImageButton } from "./UploadImage.styled";
const UploadImage = ({ setImageUrl, imageUrl }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <label htmlFor="upload-image">
          <ImageButton imageUrl={imageUrl} variant="secondary" component="span">
            <span>Upload</span>
          </ImageButton>
          <input
            id="upload-image"
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileUpload}
          />
        </label>
      </Stack>
    </Container>
  );
};
export default UploadImage;
