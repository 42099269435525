export const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

export const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

export const iconBackColorOpen = "grey.300";
export const iconBackColor = "grey.100";

export const paperStyles = (theme) => ({
  boxShadow: theme.customShadows.z1,
  width: "100%",
  minWidth: 285,
  maxWidth: 420,
  [theme.breakpoints.down("md")]: {
    maxWidth: 285,
  },
});
