import styled from "styled-components";
import DropdownButton from "react-bootstrap/DropdownButton";

export const AddCustomerDropDown = styled(DropdownButton)`
  font-size: 16px;
  font-family: "Public Sans", sans-serif !important;

  .btn {
    text-transform: initial;
    font-size: 16px;
  }

  .dropdown-toggle:after {
    content: none;
  }
`;
