import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BaseCheckBox from "Components/BaseCheckBox/BaseCheckBox";
import { useNavigate } from "react-router-dom";
import avatar from "Assets/images/icon.png";
import Avatar from "@mui/material/Avatar";

import { Container, CustomHeaderCell, AvatarCell } from "./Orders.styled";

const columns = [
  {
    field: "id",
    headerName: "Order",
    minWidth: 140,
    sortable: false,
    flex: 1,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "customer",
    headerName: "Customers",
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
    renderCell: (params) => (
      <AvatarCell>
        <Avatar alt="Customer Avatar" src={avatar} className="avatar" />
        <span className="customer-name">
          {params.value ? params.value : ""}
        </span>
      </AvatarCell>
    ),
  },
  {
    field: "createDate",
    headerName: "Create Date",
    minWidth: 220,
    flex: 1,
    sortable: false,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    minWidth: 220,
    flex: 1,
    sortable: false,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },

  {
    field: "paymentStatus",
    headerName: "Payment Status",
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
    valueGetter: (params) => (params.value === "Paid" ? "Paid" : "Pending"),
    renderCell: (params) => (
      <AvatarCell>
        <div
          style={{
            color: params.value === "Paid" ? "green" : "red",
            fontWeight: "bold",
            background: params.value === "Paid" ? "#eaffea" : "#ffecec",
            padding: "4px 8px",
            borderRadius: "4px",
            marginLeft: "20px",
          }}
        >
          {params.value}
        </div>
      </AvatarCell>
    ),
  },
  {
    field: "totalAmount",
    headerName: "Total Amount",
    minWidth: 160,
    sortable: false,
    flex: 1,
    valueFormatter: (params) => (params.value ? `$${params.value}` : "N/A"),
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
];

const generateRandomDate = () => {
  const start = new Date(2022, 0, 1);
  const end = new Date();
  const randomDate = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
  return randomDate.toLocaleString();
};

const generateRandomEmail = () => {
  const names = [
    "smith",
    "jane",
    "doe",
    "Ahmed",
    "John",
    "Daniel",
    "Adam",
    "formance",
  ];
  const randomName = names[Math.floor(Math.random() * names.length)];
  return `${randomName}${randomName.length}${randomName.length}`;
};

const generateRandomtotalAmount = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const rows = [];

for (let i = 1; i <= 20; i++) {
  rows.push({
    id: i,
    customer: generateRandomEmail(),
    createDate: generateRandomDate(),
    dueDate: generateRandomDate(),
    paymentStatus: i % 3 === 0 ? "Paid" : "Pending",
    totalAmount: generateRandomtotalAmount(10, 100),
  });
}

const Orders = () => {
  const navigate = useNavigate();

  const getOrderDetails = (params) => {
    console.log("Get Order Details:", params.row);
    navigate("/orders/invoice");
  };

  return (
    <Container>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        onRowClick={getOrderDetails}
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "hidden !important",
          },
          ".MuiDataGrid-sortIcon": {
            visibility: "hidden !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },

          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none ",
            border: "none",
          },

          "& .MuiDataGrid-cell:focus": {
            outline: "none",
            boxShadow: "none",
            border: "none",
            borderColor: "primary.light",
          },
          "& .MuiDataGrid-row:not(:last-child) .MuiDataGrid-cell": {
            borderBottom: "1px solid #ccc",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            border: "none",
            overflow: "hidden",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "#00a84f	",
          },
          "& .MuiDataGrid-toolbarContainer  .MuiButton-root": {
            color: "#fff !important",
            width: 100,
            backgroundColor: "#00a84f",
            marginBottom: "10px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            justifyContent: "center",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center",
          },
        }}
        localeText={{
          toolbarDensity: "Size",
          toolbarDensityLabel: "Size",
          toolbarDensityCompact: "Small",
          toolbarDensityStandard: "Medium",
          toolbarDensityComfortable: "Large",
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
          BaseCheckbox: BaseCheckBox,
        }}
      />
    </Container>
  );
};
export default Orders;
