import React, { useContext } from "react";
import DeleteContext from "Contexts/DeleteModal-Context";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import BaseCheckBox from "Components/BaseCheckBox/BaseCheckBox";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import productOne from "Assets/images/product001.webp";
import productTwo from "Assets/images/product0601.webp";
import productThree from "Assets/images/product003.webp";
import productFour from "Assets/images/product04.webp";
import productFive from "Assets/images/product0101.webp";
import productSix from "Assets/images/product05.webp";
import productSeven from "Assets/images/product06.webp";
import {
  AvatarCell,
  CustomHeaderCell,
  Container,
  StatusContainer,
} from "./Products.styled";
import DeleteModal from "Components/DeleteModal/DeleteModal";

const generateRandomStatus = () => {
  return Math.random() < 0.5 ? "Active" : "Not Active";
};

const productNames = [
  // "Smartphone",
  "Laptop",
  "Tablet",
  // "Headphones",
  "Camera",
  // "Smartwatch",
  // "Gaming Console",
  // "Printer",
  // "Bluetooth Speaker",
  // "Fitness Tracker",
  // Add more product names here
];

// Function to generate a random product name
const generateRandomProductName = () => {
  const randomIndex = Math.floor(Math.random() * productNames.length);
  return productNames[randomIndex];
};

const generateRandomInventory = () => {
  return Math.floor(Math.random() * 101);
};

const generateRandomCode = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let code = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }
  return code;
};
const generateRandomProductImage = () => {
  const productImages = [
    productOne,
    productTwo,
    productThree,
    productFour,
    productFive,
    productSix,
    productSeven,
  ];
  const randomIndex = Math.floor(Math.random() * productImages.length);
  return productImages[randomIndex];
};

const generateRandomSize = () => {
  const sizes = ["7", "8", "9", "10", "11"];
  return sizes[Math.floor(Math.random() * sizes.length)];
};

const generateRandomColor = () => {
  const colors = ["Red", "Green", "Blue", "Black", "White"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const columns = [
  {
    field: "id",
    headerName: "Code",
    minWidth: 150,
    flex: 1,
    key: "id",
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "inventory",
    headerName: "Inventory",
    minWidth: 150,
    flex: 1,
    key: "inventory",
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
];

const rows = Array.from({ length: 20 }, (_, index) => ({
  id: generateRandomCode(),
  productName: generateRandomProductName(),
  status: generateRandomStatus(),
  inventory: generateRandomInventory(),
  productImage: generateRandomProductImage(),
}));

const Products = () => {
  const ctx = useContext(DeleteContext);

  return (
    <Container>
      {/* DataGrid */}
      <DataGrid
        sx={{
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "#00a84f	",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },

          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none ",
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            justifyContent: "center",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center",
          },

          "& .MuiDataGrid-cell:focus": {
            outline: "none",
            boxShadow: "none",
            border: "none",
            borderColor: "primary.light",
          },
          "& .MuiDataGrid-row:not(:last-child) .MuiDataGrid-cell": {
            borderBottom: "1px solid #ccc",
          },
          "& .MuiDataGrid-toolbarContainer  .MuiButton-root": {
            color: "#fff !important",
            width: 100,
            backgroundColor: "#00a84f",
            marginBottom: "10px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          },
        }}
        rows={rows}
        columns={[
          {
            field: "productName",
            headerName: "Product",
            minWidth: 200,
            flex: 1,
            key: "productName",
            renderHeader: (params) => (
              <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
            ),

            renderCell: (params) => (
              <AvatarCell isProductNameCell={params.field === "productName"}>
                <Avatar
                  alt="Product Avatar"
                  src={params.row.productImage}
                  className="avatar"
                />
                <div>
                  <span className="product-name">
                    <div>{params.value ? params.value : ""}</div>
                    <div>Size: {generateRandomSize()}</div>
                  </span>
                </div>
              </AvatarCell>
            ),
          },
          // {
          //   field: "icon",
          //   flex: 1,
          //   key: "icon",
          //   renderHeader: (params) => (
          //     <div style={{ display: "none" }}>{params.colDef.headerName}</div>
          //   ),

          //   renderCell: (params) => (
          //     <AvatarCell>
          //       <Avatar
          //         alt="Product Avatar"
          //         src={params.row.productImage}
          //         className="avatar"
          //       />
          //     </AvatarCell>
          //   ),
          // },

          ...columns,
          {
            field: "status",
            headerName: "Status",
            key: "status",
            flex: 1,
            renderHeader: (params) => (
              <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
            ),
            renderCell: (params) => (
              <AvatarCell>
                <div>
                  <StatusContainer status={params.value}>
                    {params.value ? params.value : ""}
                  </StatusContainer>
                </div>
              </AvatarCell>
            ),
          },
          {
            field: "actions",
            headerName: "Actions",
            minWidth: 160,
            key: "actions",
            flex: 1,
            renderHeader: (params) => (
              <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
            ),
            renderCell: (params) => (
              <Stack direction="row" spacing={1}>
                <IconButton aria-label="view" size="small">
                  <VisibilityIcon
                    fontSize="small"
                    color="secondary"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
                <IconButton aria-label="edit" size="small">
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="small"
                    color="primary"
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={ctx.setShowDeleteModal}
                >
                  <DeleteIcon
                    fontSize="small"
                    color="error"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
              </Stack>
            ),
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
          BaseCheckbox: BaseCheckBox,
        }}
      />
      <DeleteModal type={"product"} />
    </Container>
  );
};

export default Products;
