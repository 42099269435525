import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Container } from "./DraftEditor.styled";

const DraftEditor = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  console.log(convertedContent);

  return (
    <Container>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: [
            "inline",
            // "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "link",
            "emoji",
            "history",
          ],
        }}
        hashtag={{
          separator: " ",
          trigger: "#",
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [
            { text: "JavaScript", value: "javascript", url: "js.com" },
            { text: "Cpp", value: "C++", url: "cpp.com" },
            { text: "python", value: "python", url: "python.com" },
            { text: "Golang", value: "golang", url: "go.com" },
          ],
        }}
        placeholder="Write your comment here"
      />
    </Container>
  );
};

export default DraftEditor;
