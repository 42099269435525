// material-ui
import { Box } from "@mui/material";
import background from "Assets/images/icon.png";

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
  return (
    <Box
      sx={{ position: "absolute", filter: "blur(18px)", zIndex: -1, bottom: 0 }}
    >
      <img src={background} alt="Orgasafe" />
    </Box>
  );
};

export default AuthBackground;
