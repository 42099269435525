import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #fff;
`;

export const CustomHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: #fff;
  font-size: 15px;
  padding: 12px;
  border-bottom: 2px solid #ccc;
  cursor: pointer;
`;

export const AvatarCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1); /* Apply zoom effect on hover */
  }

  .avatar {
    border: 1px solid white; /* Add a white border for circular effect */
    border-radius: 50%; /* Make the avatar circular */
    width: 40px; /* Set the desired width */
    height: 40px; /* Set the desired height */
    object-fit: cover;
  }

  .customer-name {
    margin-left: 8px;
  }
`;
