// project import

import MinimalLayout from "Components/MinimalLayout";

// render - login
import AuthLogin from "Components/Login/Login";
import AuthRegister from "Components/Register/Register";

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "register",
      element: <AuthRegister />,
    },
  ],
};

export default LoginRoutes;
