import styled from "styled-components";
import Button from "@mui/material/Button";

export const ImageButton = styled(Button)(({ imageUrl }) => ({
  display: "flex",
  height: "100px",
  width: "100%",
  backgroundImage: imageUrl ? `url(${imageUrl})` : "",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "black",
  color: "black",
  fontSize: "0.875rem",
  lineHeight: "1.4375em",
  fontFamily: "'Public Sans', sans-serif",
  fontWeight: 400,
  padding: 0,
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  transition: "background-color 0.3s ease-in-out", // Add a transition for a smooth effect
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color on hover
    "& span": {
      visibility: "visible", // Show the "Upload" word on hover
    },
  },
  "& span": {
    position: "absolute",
    fontSize: "0.875rem",
    transform: "translate(-50%, -50%)",
  },
}));

export const Label = styled.label`
  display: flex;
  position: relative;
  justify-content: center;
  
`;
