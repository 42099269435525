import { createContext, useState } from "react";

const AddCustomerContext = createContext({
  showAddCustomerModal: false,
  setShowAddCustomerModal: () => {},
  closeAddCustomerModal: () => {},
});

export const AddCustomerContextProvider = (props) => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const handleAddCustomerModal = () => {
    setShowAddCustomerModal(true);
  };

  const handleCloseAddCustomerModal = () => {
    setShowAddCustomerModal(false);
  };

  return (
    <AddCustomerContext.Provider
      value={{
        showAddCustomerModal: showAddCustomerModal,
        setShowAddCustomerModal: handleAddCustomerModal,
        closeAddCustomerModal: handleCloseAddCustomerModal,
      }}
    >
      {props.children}
    </AddCustomerContext.Provider>
  );
};

export default AddCustomerContext;
