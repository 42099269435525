import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// material-ui
import { ButtonBase, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Logo from "Assets/images/logo.png";

// project import
import config from "config";
import { activeItem } from "Store/reducers/menu";

const LogoSection = ({ sx, to }) => {
  const { defaultId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      to={!to ? config.defaultPath : to}
      sx={sx}
    >
      <Box
        sx={{
          width: "180px",
          height: "64px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          alt="avatar"
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      </Box>
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;
