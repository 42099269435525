import styled from "@emotion/styled";

export const AvatarCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    color: blue;
  }

  .avatar {
    border: 1px groove blanchedalmond;
    border-radius: 0;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 8px;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .product-name {
    font-weight: bold;
    font-size: 13px;
  }

  .product-size,
  .product-color {
    font-size: 12px;
  }
`;

export const CustomHeaderCell = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  font-size: 15px;
  padding: 6px;
  border-bottom: 2px solid #ccc;
`;

export const Container = styled.div`
  max-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const StatusContainer = styled.span`
  min-width: 100px;
  max-width: 150px;
  font-weight: bold;
  background-color: ${({ status }) =>
    status === "Active" ? "rgb(230, 255, 251)" : "rgb(246, 255, 237)"};
  border-color: rgb(92, 219, 211);

  color: ${({ status }) =>
    status === "Active" ? "rgb(19, 194, 194)" : "rgb(82, 196, 26)"};
  max-width: 100%;
  font-family: "Public Sans", sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px rgb(217, 217, 217);
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;
`;
