import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MainCard from "Components/MainCard/MainCard";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddCustomerContext from "Contexts/AddCustomerModal-Context";
import Dropdown from "react-bootstrap/Dropdown";
import { AddCustomerDropDown } from "./Breadcrumbs.styled";

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ navigation, title, ...others }) => {
  const navigate = useNavigate();
  const ctx = useContext(AddCustomerContext);
  const location = useLocation();
  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const [activeClicked, setActiveClicked] = useState(false);
  console.log("Location", location);
  console.log("item", item);
  console.log("main", main);
  // set active item state
  const getCollapse = useCallback(
    (menu) => {
      if (menu.children) {
        for (const child of menu.children) {
          if (child.type === "collapse") {
            getCollapse(child);
          } else if (child.type === "item") {
            if (location.pathname.startsWith(child.url)) {
              setMain(menu);
              setItem(child);
              break;
            }
          }
        }
      }
    },
    [location.pathname]
  );
  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type === "group") {
        getCollapse(menu);
      }
      return false;
    });
  }, [location, navigation, getCollapse]);

  // only used for component demo breadcrumbs
  if (location.pathname === "/breadcrumbs") {
    location.pathname = "/dashboard";
  }

  let mainContent;
  let itemContent;
  let breadcrumbContent = null;
  let itemTitle = "";

  // collapse item
  if (main && main.type === "collapse") {
    mainContent = (
      <Typography
        component={Link}
        to={main.url}
        variant="h5"
        sx={{ textDecoration: "none" }}
        color="textSecondary"
      >
        {main.title}
      </Typography>
    );
  }

  // items
  if (item && item.type === "item") {
    itemTitle = item.title;
    itemContent = (
      <Typography
        component={Link}
        to={item.url}
        variant="subtitle1"
        color="textPrimary"
        onClick={() => setActiveClicked(true)}
      >
        {itemTitle}
      </Typography>
    );

    breadcrumbContent = (
      <MainCard
        border={false}
        sx={{
          mb: 3,
          bgcolor: "transparent",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "inherit",
          overflow: "unset",
        }}
        {...others}
        content={false}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          {title && (
            <Grid item sx={{ mb: 3, fontSize: "16px" }}>
              <Typography variant="h3">{item.title}</Typography>
            </Grid>
          )}
          <Grid item>
            <MuiBreadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Typography
                component={Link}
                to="/dashboard"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
              >
                Home
              </Typography>
              {main && !activeClicked && mainContent}
              {itemContent}
            </MuiBreadcrumbs>
          </Grid>
        </Grid>

        {location.pathname === "/customers" && (
          <AddCustomerDropDown
            title="Add Customer"
            variant="success text-inherit"
          >
            <Dropdown.Item onClick={ctx.setShowAddCustomerModal}>
              Manually
            </Dropdown.Item>
            <Dropdown.Item>Import ERM</Dropdown.Item>
          </AddCustomerDropDown>
        )}

        {location.pathname === "/products" && (
          <AddCustomerDropDown title="Add Product" variant="success">
            <Dropdown.Item onClick={() => navigate("/add-product")}>
              Manually
            </Dropdown.Item>
            <Dropdown.Item>import ERM</Dropdown.Item>
          </AddCustomerDropDown>
        )}
        {location.pathname === "/orders" && (
          <AddCustomerDropDown title="Add order" variant="success">
            <Dropdown.Item onClick={() => navigate("/add-order")}>
              Manually
            </Dropdown.Item>
          </AddCustomerDropDown>
        )}
      </MainCard>
    );
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  title: PropTypes.bool,
};

export default Breadcrumbs;
