import { Box, useMediaQuery } from "@mui/material";
import Search from "./Search";
import Profile from "Layouts/Profile/Profile";
import Logo from "Components/LogoSection/LogoSection";
import Notification from "Layouts/Notification/Notification";
import MobileSection from "./MobileSection";

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Box sx={{ margin: "0 20px", display: "flex", alignItems: "center" }}>
        <Logo />
      </Box>

      {!matchesXs && <Search />}

      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <Notification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
