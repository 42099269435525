// project import
import Routes from "Routes";
import ThemeCustomization from "Themes";
import ScrollTop from "Components/ScrollTop/ScrollTop";

// Import Local Storage
import useLocalStorage from "Hooks/useLocalStorage";

// Import theme provider from styled components
import { ThemeProvider } from "styled-components";

// Import themes
import darkTheme from "Themes/darkTheme";
import defaultTheme from "Themes/defaultTheme";
import lightTheme from "Themes/lightTheme";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function App() {
  // State to store the current theme of the website
  const [theme, setTheme] = useLocalStorage(
    "theme",
    JSON.stringify({
      ...defaultTheme,
      ...lightTheme,
    })
  );

  /**
   * Function to toggle the theme of the website
   * It will change the theme from light to dark and vice versa
   */
  const handleToggleTheme = () => {
    if (JSON.parse(theme).id === "dark") {
      setTheme(
        JSON.stringify({
          ...defaultTheme,
          ...lightTheme,
        })
      );
    } else {
      setTheme(
        JSON.stringify({
          ...defaultTheme,
          ...darkTheme,
        })
      );
    }
  };
  return (
    <ThemeProvider theme={JSON.parse(theme)}>
      <ThemeCustomization>
        <ScrollTop>
          <Routes handleToggleTheme={handleToggleTheme} />
        </ScrollTop>
      </ThemeCustomization>
    </ThemeProvider>
  );
}
export default App;
