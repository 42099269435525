import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useState } from "react"; // Import useState

// material-ui
import { List, ListItemButton, ListItemText } from "@mui/material";

// project import
import NavItem from "Layouts/NavItem/NavItem";
import NavCollapse from "Layouts/NavCollapse/NavCollapse";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  const [open, setOpen] = useState(true);

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case "collapse":
        return <NavCollapse key={menuItem.id} item={menuItem} />;
      case "item":
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return null;
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <ListItemButton
            sx={{ pl: 3, mb: 1.5 }}
            onClick={() => setOpen(!open)}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: "subtitle2",
                color: "textSecondary",
              }}
            >
              {item.title}
            </ListItemText>
          </ListItemButton>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
