// Assets
import {
  DashboardOutlined,
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  ContactsOutlined,
  TeamOutlined,
  MacCommandOutlined,
  QrcodeOutlined,
  ReconciliationOutlined,
  DribbbleOutlined,
  PrinterOutlined,
  CodeSandboxOutlined,
  FormatPainterOutlined,
  BellOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  DashboardOutlined,
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  ContactsOutlined,
  TeamOutlined,
  MacCommandOutlined,
  QrcodeOutlined,
  ReconciliationOutlined,
  DribbbleOutlined,
  PrinterOutlined,
  CodeSandboxOutlined,
  FormatPainterOutlined,
  BellOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.DashboardOutlined,
    },
    {
      id: "orders",
      title: "Orders",
      type: "item",
      url: "/orders",
      icon: icons.AntDesignOutlined,
    },

    {
      id: "products",
      title: "Products",
      type: "item",
      url: "/products",
      icon: icons.MacCommandOutlined,
    },
    {
      id: "collections",
      title: "Collections",
      type: "item",
      url: "/collections",
      icon: icons.ReconciliationOutlined,
    },
    {
      id: "brands",
      title: "Brands",
      type: "item",
      url: "/brands",
      icon: icons.DribbbleOutlined,
    },
    {
      id: "inventory",
      title: "Inventory",
      type: "item",
      url: "/inventory",
      icon: icons.BellOutlined,
    },

    {
      id: "customer",
      title: "Customers",
      type: "item",
      url: "/customers",
      icon: icons.TeamOutlined,
    },
    {
      id: "content",
      title: "Content",
      type: "item",
      url: "/content",
      icon: icons.ContactsOutlined,
    },
    {
      id: "Analytics",
      title: "Analytics",
      type: "item",
      url: "/analytics",
      icon: icons.QrcodeOutlined,
    },
    {
      id: "Discounts",
      title: "Discounts",
      type: "item",
      url: "/discounts",
      icon: icons.CodeSandboxOutlined,
    },
  ],
};

export default dashboard;
