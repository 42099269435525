import styled from "styled-components";

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    border: 1px groove orange;
  }

  .avatar {
    border: 1px groove blanchedalmond;
    border-radius: 0;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
`;

export const CustomHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: #333;
  font-size: 15px;
  padding: 12px;
  border-bottom: 2px solid #ccc;
  cursor: pointer;
`;
