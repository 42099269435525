import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ImageButton = styled(Button)(({ imageUrl }) => ({
  borderRadius: "50%",
  backgroundImage: imageUrl ? `url(${imageUrl})` : "",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "70px",
  height: "70px",
  color: "white",
  fontSize: "0.875rem",
  lineHeight: "1.4375em",
  fontFamily: "'Public Sans', sans-serif",
  fontWeight: 400,
  padding: 0,
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  transition: "background-color 0.3s ease-in-out", // Add a transition for a smooth effect
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color on hover
    "& span": {
      visibility: "visible", // Show the "Upload" word on hover
    },
  },
  "& span": {
    visibility: "hidden", // Hide the "Upload" word by default
    position: "absolute",
    fontSize: "0.875rem",

    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
