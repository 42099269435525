import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import DropDownProduct from "Components/DropDownProduct/DropDownProduct";
import MediaProduct from "Components/MediaProduct/MediaProduct";
import DraftEditor from "Components/DraftEditor/DraftEditor";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import {
  ProductContainer,
  MainContainer,
  SideBar,
  StatusContainer,
  AddCustomerDropDown,
  AddCustomerDropDownWrapper,
  FirstContainer,
  SubFirstContainer,
  FirstSection,
  LabelTitle,
  MediaContainer,
} from "./AddProduct.styled";

const AddProduct = ({ customerData }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState("");
  const [price, setPrice] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [costPerItem, setCostPerItem] = useState("");
  const [profit, setProfit] = useState("");
  const [margin, setMargin] = useState("");
  const [code, setCode] = useState("");
  const [quantity, setQuantity] = useState("");
  const [size, setSize] = useState("");
  const [status, setStatus] = useState("Status");
  const [draft, setDraft] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productCollection, setProductCollection] = useState("");
  const [imageUrl, setImageUrl] = useState();

  const handleSubmit = () => {
    // Here you can handle the form submission and create the product
    const productData = {
      title,
      description,
      media,
      pricing: {
        price,
        comparePrice,
        costPerItem,
        profit,
        margin,
      },
      inventory: {
        code,
        quantity,
      },
      variant: { size },
      Status: {
        status,
        draft,
      },
      productOrganization: {
        productBrand,
        productCollection,
      },
    };
    console.log("Product data to be saved:", productData);
  };
  const handleStatusChange = (event) => {
    setStatus(event);
  };
  useEffect(() => {
    if (price) {
      setProfit(price - costPerItem);
      setMargin((((price - costPerItem) / price) * 100).toFixed(2));
    } else {
      setProfit("");
      setMargin("");
    }
  }, [price, costPerItem]);

  return (
    <ProductContainer>
      <MainContainer>
        {/* Title */}
        <FirstContainer>
          <SubFirstContainer>
            <Form.Group controlId="title" className="mb-3 border-danger">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Product Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>

            {/* Description */}
            <Form.Group controlId="description" className="mb-3">
              <Form.Label>Description</Form.Label>
              <DraftEditor />
            </Form.Group>
          </SubFirstContainer>
        </FirstContainer>
        {/* Media */}

        <FirstContainer>
          <SubFirstContainer>
            <LabelTitle>Media</LabelTitle>
            <Form.Group controlId="media" className="mb-3 mt-2">
              <Form.Control
                type="text"
                placeholder="Product Media"
                value={media}
                onChange={(e) => setMedia(e.target.value)}
              />
            </Form.Group>
            {/* <MediaContainer>
              <MediaProduct imageUrl={imageUrl} setImageUrl={setImageUrl} />
            </MediaContainer> */}
          </SubFirstContainer>
        </FirstContainer>
        {/* Pricing */}
        <FirstContainer>
          <SubFirstContainer>
            <LabelTitle>Pricing</LabelTitle>
            <FirstSection>
              <Form.Group controlId="price" className="mb-3 ">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="$ 0.0"
                  value={price}
                  min={0}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="comparePrice" className="mb-3">
                <Form.Label>Compare Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="$ 0.0"
                  value={comparePrice}
                  min={0}
                  onChange={(e) => setComparePrice(e.target.value)}
                />
                {/* <span>
                  <HelpOutlineIcon />
                </span> */}
              </Form.Group>
            </FirstSection>
            <Form.Group className="mb-2 mt-2 d-flex">
              <Form.Check type="checkbox" id="checkbox" />
              <label htmlFor="checkbox">Charge tax on this product</label>
            </Form.Group>
            <FirstSection>
              <Form.Group controlId="costPerItem" className="m-2">
                <Form.Label>Cost Per Item</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="$ 0.0"
                  value={costPerItem}
                  min={0}
                  onChange={(e) => setCostPerItem(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="profit" className="m-2">
                <Form.Label>Profit</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="--"
                  value={profit}
                  disabled
                  onChange={(e) => setProfit(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="margin" className="m-2">
                <Form.Label>Margin</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="--"
                  value={margin}
                  disabled
                  onChange={(e) => setMargin(e.target.value)}
                />
              </Form.Group>
            </FirstSection>
          </SubFirstContainer>
        </FirstContainer>

        {/* Inventory */}
        <FirstContainer>
          <SubFirstContainer>
            <LabelTitle>Invenotry</LabelTitle>
            <Form.Group controlId="code" className="mb-3 mt-2">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="quantity" className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                placeholder="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Form.Group>
          </SubFirstContainer>
        </FirstContainer>
        {/*Variants */}
        <FirstContainer>
          <SubFirstContainer>
            <Form.Group controlId="size" className="mb-3">
              <Form.Label>Size</Form.Label>
              <Form.Control
                type="text"
                placeholder="Size"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              />
            </Form.Group>
          </SubFirstContainer>
        </FirstContainer>
      </MainContainer>
      <SideBar>
        <StatusContainer>
          <LabelTitle style={{ margin: "15px" }}>Status</LabelTitle>
          <AddCustomerDropDownWrapper>
            <AddCustomerDropDown
              title={status}
              eventKey="status"
              onSelect={handleStatusChange}
            >
              <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
              <Dropdown.Item eventKey="Draft">Draft</Dropdown.Item>
            </AddCustomerDropDown>
          </AddCustomerDropDownWrapper>
        </StatusContainer>
        <FirstContainer>
          <SubFirstContainer>
            <LabelTitle>Product organization</LabelTitle>
            {/* Product Organization */}
            <Form.Group controlId="productBrand" className="mb-3 mt-2">
              <Form.Label>Product Brand</Form.Label>
              <DropDownProduct word={"Brand"} />
            </Form.Group>

            <Form.Group controlId="productCollection" className="mb-3">
              <Form.Label>Product Collection</Form.Label>
              <DropDownProduct word={"Collection"} />
            </Form.Group>
          </SubFirstContainer>
        </FirstContainer>
      </SideBar>
    </ProductContainer>
  );
};

export default AddProduct;
