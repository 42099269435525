import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { ImageButton } from "./AddCard.styled";
import Typography from "@mui/material/Typography";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import QueueIcon from "@mui/icons-material/Queue";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import AddCustomerContext from "Contexts/AddCustomerModal-Context";
import AddCustomerModal from "Components/AddCustomerModal/AddCustomerModal";
import { useContext } from "react";

const shortcuts = [
  {
    icon: <ProductionQuantityLimitsIcon />,
    title: "Add Product",
    width: "20%",
    route: "/add-product",
  },
  {
    icon: <QueueIcon />,
    title: "Add Collection",
    width: "20%",
  },
  {
    icon: <BrandingWatermarkIcon />,
    title: "Add Brand",
    width: "20%",
  },
  {
    icon: <PersonOutlineIcon />,
    title: "Add Customer",
    width: "20%",
  },
];

const AddCard = () => {
  const navigate = useNavigate();

  const ctx = useContext(AddCustomerContext);

  const handleShortcutClick = (route, title) => {
    if (title === "Add Customer") {
      ctx.setShowAddCustomerModal();
    } else {
      navigate(route);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          minWidth: 100,
          width: "100%",
        }}
      >
        {shortcuts.map((shortcut, index) => (
          <ImageButton
            key={index}
            style={{
              width: shortcut.width,
              margin: "10px",
              height: "50px",
              backgroundColor: "#00a84f",
            }}
            onClick={() => handleShortcutClick(shortcut.route, shortcut.title)}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {shortcut.icon}
              <Typography variant="subtitle1" fontSize="16px" sx={{ ml: 1 }}>
                {shortcut.title}
              </Typography>
            </Box>
          </ImageButton>
        ))}
      </Box>
      <AddCustomerModal />
    </>
  );
};

export default AddCard;
