import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DeleteContext from "Contexts/DeleteModal-Context";
import { useContext } from "react";

import { ModalContainer } from "./DeleteModal.styled";

const DeleteModal = ({ type }) => {
  const ctx = useContext(DeleteContext);
  return (
    <ModalContainer show={ctx.isShownDeleteModal} onHide={ctx.closeDeleteModal}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to delete?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        By deleting {type} X, all task assigned to that {type} will also be
        deleted.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={ctx.closeDeleteModal}>
          Close
        </Button>
        <Button variant="danger" onClick={ctx.closeDeleteModal}>
          Delete
        </Button>
      </Modal.Footer>
    </ModalContainer>
  );
};

export default DeleteModal;
