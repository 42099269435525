// imports

import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const AddCustomerButton = styled(Button)`
  align-self: flex-end;
  min-width: 213px;
  margin: 10px;
  font-size: 12px;
  font-family: "Public Sans", sans-serif !important;
`;

export const ModalContainer = styled(Modal)`
  .modal-content {
    margin-top: 150px;
    position: relative;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;
      margin: 30px auto;
    }
  }

  @media (min-width: 992px) {
    .modal-lg {
      width: 900px;
    }
  }
  @media (min-width: 768px) {
    .modal-xl {
      width: 90%;
      max-width: 1200px;
    }
  }

  .modal-title {
    margin: 0px !important;
    line-height: 1.57 !important;
    font-family: "Public Sans", sans-serif !important;
    padding: 2px 15px !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProfileContainer = styled.div`
  align-items: center;
`;
export const ImageIconContainer = styled.div`
  img {
    border-radius: 50%;
    background-image: url("path/to/your/image.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 60px;
  }
`;
