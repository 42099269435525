// imports

import styled from "styled-components";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";

export const AddCustomerButton = styled(Button)`
  align-self: flex-end;
  min-width: 213px;
  margin: 10px;
  font-size: 12px;
  font-family: "Public Sans", sans-serif !important;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  background-color: #f1f1f1;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 16px 0 0 16px;
`;

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 5px;
  /* box-shadow: 0 0 10px white; */
  background-color: #ffffff;
`;
export const SubFirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`;

export const SideBar = styled.aside`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 16px 0 0 16px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 5px;
  background-color: #ffffff;
`;

export const ProfileContainer = styled.div`
  align-items: center;
`;

export const ImageIconContainer = styled.div`
  img {
    border-radius: 50%;
    background-image: url("path/to/your/image.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 60px;
  }
`;

export const AddCustomerDropDownWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem !important;
`;

export const AddCustomerDropDown = styled(DropdownButton)`
  font-size: 16px;
  font-family: "Public Sans", sans-serif !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  margin-bottom: 1rem !important;

  .dropdown-toggle:after {
    content: none;
  }

  .btn {
    text-transform: initial;
    font-size: 16px;
    width: 80%;
    align-items: center;
    background-color: white;
    color: black;
    border: 1px solid black;
    box-shadow: none !important;
    &:hover {
      background-color: #d3d3d3 !important;
    }

    &.btn:first-child:active {
      background-color: #d3d3d3 !important;
      color: black;
    }
    &.btn-primary:active:hover {
      background-color: #d3d3d3 !important;
    }

    &.btn-primary.show:hover {
      background-color: #d3d3d3 !important;
      box-shadow: none !important;
    }

    :focus {
      background: none !important;
      border: 2px solid #14a44d;
    }
    &.btn-primary:focus-visible {
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    width: 80% !important;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export const FirstSection = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelTitle = styled(Form.Label)`
  font-size: 16px;
  font-weight: bold;
`;

export const MediaContainer = styled.div`
  display: flex;
`;
