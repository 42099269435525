import styled from "styled-components";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px;
  background-color: #f0f0f0;
  padding: 20px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const SpanCode = styled.span`
  font-size: 25px;
  font-weight: bold;
  margin-right: 10px;
`;

export const DateSpan = styled.span`
  color: gray;
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddCustomerDropDownWrapper = styled.div`
  position: relative;
`;

export const AddCustomerDropDown = styled(DropdownButton)`
  font-size: 14px;
  font-family: "Public Sans", sans-serif !important;
  align-items: center;
  display: flex !important;
  justify-content: center;

  .dropdown-toggle {
    background-color: #ffffff;
    color: #000000;
    padding: 6px 12px;
    border-radius: 2px;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
  }
  &:hover {
    background-color: #d3d3d3 !important;
    border-radius: 2px;
  }

  .btn-primary {
    background-color: #d3d3d3 !important;
    box-shadow: none !important;
    font-weight: bold !important;
    font-size: 12px !important;
    text-transform: inherit !important;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  flex: 1;
  width: 100%;
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;

  span {
    flex: 1;
    &:last-child {
      text-align: right;
    }
  }
`;

export const SubHeaderBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const IconSpan = styled.span`
  margin-right: 5px;
`;

export const ProductItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  position: relative;
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const ProductName = styled.span`
  flex: 1;
  font-size: 16px;
`;

export const ProductQuantity = styled.span`
  margin-right: 10px;
  font-size: 16px;
`;

export const ProductPrice = styled.span`
  font-size: 16px;
  flex-shrink: 0;
  color: #00a84f;
`;

export const ProductSubtotal = styled.span`
  font-size: 16px;
`;
export const Spacer = styled.span`
  margin: 5px;
`;

export const ProductNotesInput = styled(Form.Control)`
  width: 20%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ProductNotesLabel = styled(Form.Label)`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

export const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  align-items: center;
`;

export const NotesColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`;
