// material-ui
import { Box } from "@mui/material";

// project import
import NavGroup from "Layouts/NavGroup/NavGroup";
import MenuItems from "MenuItems";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const navGroups = MenuItems.items.map((item) => (
    <NavGroup key={item.id} item={item} />
  ));

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
