import { useState, createContext } from "react";

const DeleteContext = createContext({
  isShownDeleteModal: false,
  setShowDeleteModal: () => {},
  closeDeleteModal: () => {},
});

export const DeleteContextProvider = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hanldeDeleteModal = () => {
    console.log("Hanlder Delete Modal");
    setShowDeleteModal(!showDeleteModal);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <DeleteContext.Provider
      value={{
        isShownDeleteModal: showDeleteModal,
        setShowDeleteModal: hanldeDeleteModal,
        closeDeleteModal: handleCloseDeleteModal,
      }}
    >
      {props.children}
    </DeleteContext.Provider>
  );
};

export default DeleteContext;
