/**
 * default theme colors which is the colors that is common between dark and light themes
 *
 */
const defaultTheme = {
  linecolor: {
    //The color of Side Border
    sideBorder: "#ccc",
  },
};

export default defaultTheme;
