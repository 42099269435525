import MainLayout from "Layouts/MainLayout/MainLayout";
import DashboardDefault from "Pages/Dashboard/Dashboard";
import Blog from "Pages/Blog/Blog";
import Orders from "Pages/Orders/Orders";
import Invoice from "Pages/Invoice/Invoice";
import NewOrder from "Pages/NewOrder/NewOrder";
import Customers from "Pages/Customers/Customers";
import Products from "Pages/Products/Products";
import AddProduct from "Pages/AddProduct/AddProduct";

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/orders",
      element: <Orders />,
    },
    {
      path: "/orders/invoice",
      element: <NewOrder />,
    },
    { path: "/customers", element: <Customers /> },
    { path: "/products", element: <Products /> },
    { path: "/settings", element: <DashboardDefault /> },
    { path: "/add-product", element: <AddProduct /> },
    {
      path: "*",
      element: <DashboardDefault />,
    },
  ],
};

export default MainRoutes;
