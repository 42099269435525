import styled from "styled-components";

export const Container = styled.div`
  /* display: flex;
  overflow: unset;
  border: 1px solid #bdbdbd;
  border-radius: 3px; */

  .wrapper-class {
    /* padding: 1rem; */
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    /* border: 1px solid #ccc; */
  }
  .rdw-editor-main {
    overflow: unset;
  }
  .public-DraftStyleDefault-ltr {
    /* background-color: #ededed; */
    /* min-height: 80px; */
  }
  .public-DraftStyleDefault-block {
    /* margin: 0.5em 0 !important; */
    line-break: anywhere;
    margin: 0 !important;
  }
  
  
`;
