import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NumberFormat from "react-number-format";

import { useState } from "react";

function createData(id, name, email, phone, totalAmount) {
  return { id, name, email, phone, totalAmount };
}

const rows = [
  createData(1, "John Doe", "john.doe@example.com", "+1234567890", 2000),
  createData(2, "Jane Smith", "jane.smith@example.com", "+0987654321", 1500),
  createData(
    3,
    "Michael Johnson",
    "michael.johnson@example.com",
    "+9876543210",
    3000
  ),
  createData(4, "Emily Brown", "emily.brown@example.com", "+9182736450", 2500),
  createData(5, "William Lee", "william.lee@example.com", "+7364958120", 1800),
  createData(
    6,
    "Sophia Wilson",
    "sophia.wilson@example.com",
    "+5467283901",
    3200
  ),
  createData(
    7,
    "James Anderson",
    "james.anderson@example.com",
    "+1239807654",
    2800
  ),
  createData(
    8,
    "Olivia Martinez",
    "olivia.martinez@example.com",
    "+9876543210",
    2100
  ),
  createData(
    9,
    "Daniel Taylor",
    "daniel.taylor@example.com",
    "+3456789120",
    2700
  ),
  createData(10, "Ava Lewis", "ava.lewis@example.com", "+9081726354", 1900),
];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  { id: "name", align: "left", disablePadding: false, label: "Name" },
  { id: "email", align: "left", disablePadding: false, label: "Email" },
  { id: "phone", align: "left", disablePadding: false, label: "Phone" },
  {
    id: "totalAmount",
    align: "right",
    disablePadding: false,
    label: "Total Amount",
  },
];

const CustomerTableHead = ({ order, orderBy }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CustomerTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

const CustomerTable = () => {
  const [order] = useState("asc");
  const [orderBy] = useState("name");
  const [selected] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: "auto",
          position: "relative",
          display: "block",
          maxWidth: "100%",
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            "& .MuiTableCell-root:first-of-type": {
              pl: 2,
            },
            "& .MuiTableCell-root:last-of-type": {
              pr: 3,
            },
          }}
        >
          <CustomerTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                    >
                      <Link color="secondary" component={RouterLink} to="">
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.phone}</TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={row.totalAmount}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomerTable;
