import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NavItem from "Layouts/NavItem/NavItem";
import { Link } from "react-router-dom";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavCollapse = ({ item, level = 0 }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleCollapseToggle = () => {
    setOpen(!open);
  };

  const renderNestedItems = (items, nestedLevel) => {
    if (!items || items.length === 0) return null;

    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {items.map((menuItem) => {
            return (
              <React.Fragment key={menuItem.id}>
                {menuItem.type === "item" ? (
                  <NavItem item={menuItem} level={nestedLevel} />
                ) : menuItem.type === "collapse" ? (
                  <NavCollapse item={menuItem} level={nestedLevel + 1} />
                ) : null}
              </React.Fragment>
            );
          })}
        </List>
      </Collapse>
    );
  };

  const textColor = "text.success";
  const iconSelectedColor = "success.main";

  // Render a Link wrapper around the ListItemButton to enable navigation
  const listItemProps = {
    component: Link,
    to: item.url,
    target: item.target || "_self",
    onClick: handleCollapseToggle,
    sx: {
      pl: 3 + level * 16,
      "&:hover": {
        bgcolor: "success.lighter", // Apply hover background color
        color: iconSelectedColor,
      },
      "&.Mui-selected": {
        bgcolor: "success.lighter",
        borderRight: `2px solid ${theme.palette.success.main}`, // Use "success.main" for border color
        color: iconSelectedColor,
        "&:hover": {
          color: iconSelectedColor,
          bgcolor: "success.lighter",
        },
      },
    },
  };

  return (
    <>
      <ListItemButton {...listItemProps}>
        {item.icon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: textColor,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <item.icon style={{ fontSize: "1.25rem" }} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color: textColor,
                ...(level !== 0 && { pl: 1 }),
                paddingLeft: "6px",
              }}
            >
              {item.title}
            </Typography>
          }
        />
        <Box sx={{ flex: 1 }} />
        {open ? (
          <ExpandMoreIcon sx={{ color: iconSelectedColor }} />
        ) : (
          <ChevronRightIcon sx={{ color: iconSelectedColor }} />
        )}
      </ListItemButton>
      {renderNestedItems(item.children, level + 2)}
    </>
  );
};

NavCollapse.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
