import React from "react";
import { avatarSX, actionSX } from "./Transactions.styled";
import MainCard from "Components/MainCard/MainCard";

// material-ui
import {
  Avatar,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

// Assets
import {
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";

export const Transactions = () => {
  // Function to generate random transaction data
  const generateRandomTransaction = () => {
    const icons = [
      {
        icon: <GiftOutlined />,
        color: "success.main",
        bgcolor: "success.lighter",
      },
      {
        icon: <MessageOutlined />,
        color: "primary.main",
        bgcolor: "primary.lighter",
      },
      {
        icon: <SettingOutlined />,
        color: "error.main",
        bgcolor: "error.lighter",
      },
    ];

    const orders = ["#002434", "#984947", "#988784", "#736292", "#910283"];
    const dates = ["Today, 2:00 AM", "5 August, 1:45 PM", "7 hours ago"];
    const amounts = ["+ $1,430", "+ $302", "+ $682", "+ $920", "+ $150"];
    const percentages = ["78%", "8%", "16%", "25%", "10%"];

    const randomIcon = icons[Math.floor(Math.random() * icons.length)];

    return {
      id: Math.floor(Math.random() * 1000),
      icon: randomIcon.icon,
      iconColor: randomIcon.color,
      iconBgColor: randomIcon.bgcolor,
      order: orders[Math.floor(Math.random() * orders.length)],
      date: dates[Math.floor(Math.random() * dates.length)],
      amount: amounts[Math.floor(Math.random() * amounts.length)],
      percentage: percentages[Math.floor(Math.random() * percentages.length)],
    };
  };

  // Generate 10 random transaction records
  const transactions = Array.from({ length: 7 }, () =>
    generateRandomTransaction()
  );

  return (
    <Grid item xs={12} md={5} lg={4}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Transaction History</Typography>
        </Grid>
        <Grid item />
      </Grid>
      <MainCard sx={{ mt: 2 }} content={false}>
        <List
          component="nav"
          sx={{
            px: 0,
            py: 0,
            "& .MuiListItemButton-root": {
              py: 1.5,
              "& .MuiAvatar-root": avatarSX,
              "& .MuiListItemSecondaryAction-root": {
                ...actionSX,
                position: "relative",
              },
            },
          }}
        >
          {transactions.map((transaction) => (
            <ListItemButton divider key={transaction.id}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    color: transaction.iconColor,
                    bgcolor: transaction.iconBgColor,
                  }}
                >
                  {transaction.icon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {transaction.order}
                  </Typography>
                }
                secondary={transaction.date}
              />
              <ListItemSecondaryAction>
                <Stack alignItems="flex-end">
                  <Typography variant="subtitle1" noWrap>
                    {transaction.amount}
                  </Typography>
                  <Typography variant="h6" color="secondary" noWrap>
                    {transaction.percentage}
                  </Typography>
                </Stack>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      </MainCard>
    </Grid>
  );
};

export default Transactions;
