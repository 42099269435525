import { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import UploadImage from "Components/UploadImage/UploadImage";
import defaultImage from "Assets/images/icon.png";
import AddCustomerContext from "Contexts/AddCustomerModal-Context";
import {
  ModalContainer,
  Container,
  ProfileContainer,
} from "./AddCustomerModal.styled";

const AddCustomerModal = ({ customerData }) => {
  const ctxCustomer = useContext(AddCustomerContext);
  const [imageUrl, setImageUrl] = useState(defaultImage);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    console.log("useEffectIIIIN");
    if (customerData) {
      setFirstName(customerData.firstName);
      setLastName(customerData.lastName);
      setCompanyName(customerData.companyName || "");
      setAddress(customerData.address || "");
      setApartment(customerData.apartment || "");
      setCity(customerData.city || "");
      setGovernorate(customerData.governorate || "");
      setPostalCode(customerData.postalCode || "");
      setPhoneNumber(customerData.phoneNumber || "");
      setEmail(customerData.email || "");
      setCountry(customerData.country || "");
      setNote(customerData.note || "");
      setImageUrl(customerData.imageUrl || defaultImage);
    }
  }, [customerData]);

  const handleClose = () => {
    console.log("Close");
    ctxCustomer.closeAddCustomerModal();
    clearData();
  };

  const clearData = () => {
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPhoneNumber(null);
    setApartment(null);
    setPostalCode(null);
    setImageUrl(null);
    setCountry(null);
    setCity(null);
    setAddress(null);
    setNote(null);
    setCompanyName(null);
  };

  const handleSubmit = () => {
    console.log({
      firstName,
      lastName,
      companyName,
      address,
      apartment,
      city,
      governorate,
      postalCode,
      phoneNumber,
      email,
      country,
      note,
    });
    ctxCustomer.closeAddCustomerModal();
  };

  return (
    <ModalContainer
      show={ctxCustomer.showAddCustomerModal}
      onHide={handleClose}
      class="modal-dialog modal-xl modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {customerData ? "Edit Customer" : "New Customer"}
        </Modal.Title>
      </Modal.Header>
      <Container>
        <ProfileContainer>
          <UploadImage imageUrl={imageUrl} setImageUrl={setImageUrl} />
        </ProfileContainer>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col">
                <Form.Group controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Daniel"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoFocus
                    required
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Smith"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group controlId="companyName" className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="address" className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="apartment" className="mb-3">
              <Form.Label>Apartment, suite, etc.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apartment, suite, etc."
                value={apartment}
                onChange={(e) => setApartment(e.target.value)}
              />
            </Form.Group>

            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="city" className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="governorate" className="mb-3">
                  <Form.Label>Governorate</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Governorate"
                    value={governorate}
                    onChange={(e) => setGovernorate(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="postalCode" className="mb-3">
                  <Form.Label>Postal code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Postal code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="phoneNumber" className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="country" className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="note" className="mb-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                placeholder="notes"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>

            {/* ... (Switch components) ... */}
          </Form>
        </Modal.Body>
      </Container>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          {customerData ? "Save" : "Add"}
        </Button>
      </Modal.Footer>
    </ModalContainer>
  );
};

export default AddCustomerModal;
