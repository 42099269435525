import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";

export const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  borderRadius: "10px",
  height: 200,
  transition: theme.transitions.create("opacity"),
  color: theme.palette.common.white,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    opacity: 0.7,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
  },
}));
