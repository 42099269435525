import React, { useContext, useState } from "react";
import DeleteContext from "Contexts/DeleteModal-Context";
import AddCustomerModal from "Components/AddCustomerModal/AddCustomerModal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BaseCheckBox from "Components/BaseCheckBox/BaseCheckBox";
import avatar from "Assets/images/icon.png";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteModal from "Components/DeleteModal/DeleteModal";
import AddCustomerContext from "Contexts/AddCustomerModal-Context";
import {
  AvatarCell,
  CustomHeaderCell,
  Container,
  StatusContainer,
} from "./Customers.styled";

const columns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 120,
    sortable: false,
    flex: 1,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "userName",
    headerName: "User Name",
    minWidth: 190,
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <AvatarCell>
        <Avatar alt="Customer Avatar" src={avatar} className="avatar" />
        <div>
          <span className="user-name">{params.value ? params.value : ""}</span>
          <br />
          <span className="subheader-name">
            {params.row.subheaderName ? params.row.subheaderName : ""}
          </span>
        </div>
      </AvatarCell>
    ),
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "contact",
    headerName: "Contact",
    minWidth: 140,
    sortable: false,
    flex: 1,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "createDate",
    headerName: "Create Date",
    minWidth: 200,
    sortable: false,
    flex: 1,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
  {
    field: "numberOfOrders",
    headerName: "No. Orders",
    minWidth: 160,
    sortable: false,
    flex: 1,
    renderHeader: (params) => (
      <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
    ),
  },
];

const generateRandomContact = () => {
  const contactPrefix = "011";
  const contactSuffix = Math.floor(
    10000000 + Math.random() * 90000000
  ).toString();
  return contactPrefix + contactSuffix;
};
const generateRandomDate = () => {
  const start = new Date(2022, 0, 1);
  const end = new Date();
  const randomDate = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
  return randomDate.toLocaleString();
};

const rows = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  numberOfOrders: ((index + 1) % 4) + 1,
  contact: generateRandomContact(),
  createDate: generateRandomDate(),
  userName: `User${index + 1}`,
  subheaderName: `email${index + 1}@company.com`,
  status: index % 3 ? "Pending" : "Verified",
}));

const Customers = () => {
  const ctx = useContext(DeleteContext);
  const ctxCustomer = useContext(AddCustomerContext);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleEditCustomer = (params) => {
    // Find the selected customer data based on the row ID
    const customer = rows.find((row) => row.id === params.row.id);
    setSelectedCustomer({
      firstName: customer.userName,
      lastName: customer.userName,
      phoneNumber: customer.contact,
      email: customer.subheaderName,
      country: "Egypt",
    });
    ctxCustomer.setShowAddCustomerModal();
    console.log(customer);
    // setSelectedCustomer(null);
  };

  return (
    <Container>
      {/* DataGrid */}
      <DataGrid
        sx={{
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            justifyContent: "center",
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "#00a84f	",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center",
          },

          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none ",
            border: "none",
          },

          "& .MuiDataGrid-cell:focus": {
            outline: "none",
            boxShadow: "none",
            border: "none",
            borderColor: "primary.light",
          },
          "& .MuiDataGrid-row:not(:last-child) .MuiDataGrid-cell": {
            borderBottom: "1px solid #ccc",
          },
          "& .MuiDataGrid-toolbarContainer  .MuiButton-root": {
            color: "#fff !important",
            width: 100,
            backgroundColor: "#00a84f",
            marginBottom: "10px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          },
        }}
        disableColumnMenu
        rows={rows}
        columns={[
          ...columns,
          {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            sortable: false,
            // flex: 1,
            renderHeader: (params) => (
              <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
            ),
            renderCell: (params) => (
              <AvatarCell>
                <div>
                  <StatusContainer status={params.value}>
                    {params.value ? params.value : ""}
                  </StatusContainer>
                </div>
              </AvatarCell>
            ),
          },
          {
            field: "actions",
            headerName: "Actions",
            minWidth: 200,
            sortable: false,
            // flex: 1,
            renderHeader: (params) => (
              <CustomHeaderCell>{params.colDef.headerName}</CustomHeaderCell>
            ),
            renderCell: (params) => (
              <Stack direction="row" spacing={1}>
                <IconButton aria-label="view" size="small">
                  <VisibilityIcon
                    fontSize="small"
                    color="secondary"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => handleEditCustomer(params)}
                >
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="small"
                    color="primary"
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={ctx.setShowDeleteModal}
                >
                  <DeleteIcon
                    fontSize="small"
                    color="error"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
              </Stack>
            ),
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        localeText={{
          toolbarDensity: "Size",
          toolbarDensityLabel: "Size",
          toolbarDensityCompact: "Small",
          toolbarDensityStandard: "Medium",
          toolbarDensityComfortable: "Large",
        }}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
          BaseCheckbox: BaseCheckBox,
        }}
      />
      <DeleteModal type={"user"} />

      <AddCustomerModal customerData={selectedCustomer} />
    </Container>
  );
};

export default Customers;
